<template>
  <div>
    <a-row style="background-color: #fff; padding: 8px 12px; margin-bottom: 8px">
      <a-col :span="8">
        <div style="margin: 8px; font-size: 16px">
          <span>统计日期: {{ statisticalDate }}</span>
        </div>
      </a-col>
      <a-col :span="8">
        <div style="text-align: center; background-color: #fff; font-size: 26px; font-weight: bold">WMS仓库综合看板</div>
      </a-col>
      <a-col :span="8">
        <div style="margin: 8px; font-size: 16px">
          <span style="float: right">当前时间: {{ currentTime }}</span>
          <!-- <span style="float: right">
            <a-icon type="fullscreen" style="font-size: 20px; color: #1890ff" />
          </span> -->
        </div>
      </a-col>
    </a-row>

    <a-row :gutter="[8, 8]">
      <a-col :span="8">
        <a-card :bordered="false" size="small" :body-style="{ padding: '8px 0px' }" style="overflow: hidden">
          <div slot="title">
            <img :src="stockInImage" width="16" height="16" style="margin-top: -2px" />
            <span style="margin-left: 8px">今日入库量</span>
          </div>

          <div style="height: 140px">
            <a-card-grid class="smallCard" :hoverable="false">
              <span class="number">{{ overviewItem.finished_stock_in_quantity }}</span>
              <span class="intro">今日已完成</span>
            </a-card-grid>
            <a-card-grid class="smallCard" :hoverable="false">
              <span class="number" style="color: #1890ff">{{ overviewItem.unfinished_stock_in_quantity }}</span>
              <span class="intro">待入库</span>
            </a-card-grid>
            <div style="margin-bottom: 24px">
              <a-progress :percent="overviewItem.stockInPercentage" style="width: 100%; padding: 0px 36px" />
            </div>
          </div>
        </a-card>
      </a-col>
      <a-col :span="8">
        <a-card :bordered="false" size="small" :body-style="{ padding: '8px 0px' }" style="overflow: hidden">
          <div slot="title">
            <img :src="stockOutImage" width="16" height="16" style="margin-top: -2px" />
            <span style="margin-left: 8px">今日出库量</span>
          </div>

          <div style="height: 140px">
            <a-card-grid class="smallCard" :hoverable="false">
              <span class="number">{{ overviewItem.finished_stock_out_quantity }}</span>
              <span class="intro">今日已完成</span>
            </a-card-grid>
            <a-card-grid class="smallCard" :hoverable="false">
              <span class="number" style="color: #ffa940">{{ overviewItem.unfinished_stock_out_quantity }}</span>
              <span class="intro">待出库</span>
            </a-card-grid>
            <div style="margin-bottom: 24px">
              <a-progress
                :percent="overviewItem.stockOutPercentage"
                strokeColor="#ffa940"
                style="width: 100%; padding: 0px 36px"
              />
            </div>
          </div>
        </a-card>
      </a-col>
      <a-col :span="8">
        <a-card :bordered="false" size="small" :body-style="{ padding: '8px 0px' }" style="overflow: hidden">
          <div slot="title">
            <img :src="materialImage" width="16" height="16" style="margin-top: -2px" />
            <span style="margin-left: 8px">可用库位占比</span>
          </div>

          <div style="height: 140px; text-align: center">
            <a-card-grid class="smallCard" :hoverable="false">
              <div style="margin-top: 24px">
                <span class="number">
                  <span style="color: #bae637">{{ overviewItem.occupied_location_count }}</span>
                  <span>/{{ overviewItem.total_location_count }}</span>
                </span>
                <span class="intro">占用</span>
              </div>
            </a-card-grid>
            <a-card-grid class="smallCard" :hoverable="false">
              <a-progress
                type="circle"
                :percent="overviewItem.locationPercentage"
                :format="(percent) => percent + '%'"
                strokeColor="#bae637"
                :width="90"
              />
            </a-card-grid>
          </div>
        </a-card>
      </a-col>
    </a-row>

    <a-row :gutter="[8, 8]">
      <a-col :span="8">
        <a-card :bordered="false" size="small" style="height: 240px">
          <template slot="title">客户库存占比</template>
          <div id="container3" />
        </a-card>
      </a-col>
      <a-col :span="8">
        <a-card :bordered="false" size="small" style="height: 240px">
          <template slot="title">一周入库趋势图</template>
          <template slot="extra">
            <a-range-picker
              :value="dateRangeStockIn"
              size="small"
              :allowClear="false"
              style="width: 200px"
              @change="changeDateRangeStockIn"
            />
          </template>
          <div id="container4" />
        </a-card>
      </a-col>
      <a-col :span="8">
        <a-card :bordered="false" size="small" style="height: 240px">
          <template slot="title">一周出库趋势图</template>
          <template slot="extra">
            <a-range-picker
              :value="dateRangeStockOut"
              size="small"
              :allowClear="false"
              style="width: 200px"
              @change="changeDateRangeStockOut"
            />
          </template>
          <div id="container5" />
        </a-card>
      </a-col>
    </a-row>

    <a-row :gutter="[8, 8]">
      <a-col :span="16">
        <a-card :bordered="false" size="small" style="height: 240px">
          <template slot="title">实时出入库量信息概览</template>
          <template slot="extra">
            <a-range-picker
              :value="dateRangeStoreInfo"
              size="small"
              :allowClear="false"
              style="width: 320px"
              :showTime="true"
              @change="changeDateRangeStoreInfo"
            />
          </template>
          <div id="container1" />
        </a-card>
      </a-col>
      <a-col :span="8">
        <a-card :bordered="false" size="small" style="height: 240px">
          <template slot="title">库存趋势图</template>
          <template slot="extra">
            <a-range-picker
              :value="dateRangeInventory"
              size="small"
              :allowClear="false"
              style="width: 200px"
              @change="changeDateRangeInventory"
            />
          </template>
          <div id="container2" />
        </a-card>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { homeOverview, homeStockInTrend, homeStockOutTrend, homeStockTrend } from "@/api/data";
import { materialTopTenList, clientProportionList, storeInfoList } from "@/api/statistic";
import { Line, Pie, Column } from "@antv/g2plot";
import NP from "number-precision";
import { mapState } from "vuex";
import moment from "moment";

export default {
  data() {
    return {
      stockInImage: require("./StockIn.png"),
      stockOutImage: require("./StockOut.png"),
      materialImage: require("./Material.png"),

      container1: null,
      container2: null,
      container3: null,
      container4: null,
      container5: null,

      statisticalDate: "",
      currentTime: "",
      overviewItem: {},

      StockInTrendData: [],
      StockOutTrendData: [],
      InventoryTrendData: [],
      StoreInfoData: [],
      dateRangeStockIn: [moment().add(-7, "days").startOf("day"), moment().startOf("day")],
      dateRangeStockOut: [moment().add(-7, "days").startOf("day"), moment().startOf("day")],
      dateRangeInventory: [moment().add(-7, "days").startOf("day"), moment().startOf("day")],
      dateRangeStoreInfo: [moment().startOf("day"), moment().add(1, "days").startOf("day")],

      materialTopTenData: [],
    };
  },
  computed: {
    ...mapState({
      curWarehouse: (state) => state.user.currentWarehouse,
    }),
  },
  methods: {
    initData() {
      this.container1 = new Line("container1", {
        data: [],
        autoFit: false,
        xField: "date",
        yField: "quantity",
        seriesField: "type",
        label: {},
        meta: { date: { alias: "时间" }, quantity: { alias: "数量" } },
        height: 160,
      });

      this.container2 = new Line("container2", {
        data: [],
        autoFit: false,
        xField: "inventory_date",
        yField: "quantity",
        label: {},
        meta: { inventory_date: { alias: "日期" }, quantity: { alias: "库存数量" } },
        height: 160,
      });

      this.container3 = new Pie("container3", {
        data: [],
        autoFit: false,
        meta: {
          client_name: { alias: "客户", range: [0, 1] },
          quantity: { alias: "数量" },
        },
        appendPadding: 10,
        angleField: "quantity",
        colorField: "client_name",
        height: 160,
      });

      this.container4 = new Column("container4", {
        data: [],
        autoFit: false,
        xField: "date",
        yField: "quantity",
        label: {},
        meta: { date: { alias: "日期" }, quantity: { alias: "入库数量" } },
        height: 160,
      });

      this.container5 = new Column("container5", {
        data: [],
        autoFit: false,
        xField: "date",
        yField: "quantity",
        label: {},
        meta: { date: { alias: "日期" }, quantity: { alias: "出库数量" } },
        height: 160,
        color: "#ffa940",
      });

      this.container1.render();
      this.container2.render();
      this.container3.render();
      this.container4.render();
      this.container5.render();

      this.statisticalDate = moment().format("YYYY-MM-DD");
      const timer1 = setInterval(() => {
        this.currentTime = moment().format("YYYY-MM-DD HH:mm:ss");
      }, 500);

      this.getData();
      const timer2 = setInterval(() => {
        this.getData();
      }, 3000);

      this.$once("hook:beforeDestroy", () => {
        clearInterval(timer1);
        clearInterval(timer2);
      });
    },
    getData() {
      this.getOverview();
      this.getTopTen();

      this.getTrendsSI(
        this.dateRangeStockIn[0].format("YYYY-MM-DD"),
        this.dateRangeStockIn[1].clone().add(1, "days").format("YYYY-MM-DD")
      );
      this.getTrendsSO(
        this.dateRangeStockOut[0].format("YYYY-MM-DD"),
        this.dateRangeStockOut[1].clone().add(1, "days").format("YYYY-MM-DD")
      );
      this.getTrendsInventory(
        this.dateRangeInventory[0].format("YYYY-MM-DD"),
        this.dateRangeInventory[1].clone().add(1, "days").format("YYYY-MM-DD")
      );
      this.getStoreInfo(
        this.dateRangeStoreInfo[0].format("YYYY-MM-DD HH:mm:ss"),
        this.dateRangeStoreInfo[1].format("YYYY-MM-DD HH:mm:ss")
      );
    },
    getTopTen() {
      // materialTopTenList({ warehouse: this.curWarehouse }).then((data) => {
      //   this.materialTopTenData = data.results;
      //   this.container3.changeData(this.materialTopTenData);
      // });

      clientProportionList({ warehouse: this.curWarehouse }).then((data) => {
        this.materialTopTenData = data.results;
        this.container3.changeData(this.materialTopTenData);
      });
    },
    getOverview() {
      homeOverview({ warehouse: this.curWarehouse }).then((data) => {
        let stockInTotal = data.finished_stock_in_quantity + data.unfinished_stock_in_quantity;
        data.stockInPercentage = NP.divide(data.finished_stock_in_quantity, stockInTotal, 0.01);
        data.stockInPercentage = NP.round(data.stockInPercentage, 0);

        let stockOutTotal = data.finished_stock_out_quantity + data.unfinished_stock_out_quantity;
        data.stockOutPercentage = NP.divide(data.finished_stock_out_quantity, stockOutTotal, 0.01);
        data.stockOutPercentage = NP.round(data.stockOutPercentage, 0);

        data.locationPercentage = NP.divide(data.occupied_location_count, data.total_location_count, 0.01);
        data.locationPercentage = NP.round(data.locationPercentage, 0);
        this.overviewItem = data;
      });
    },
    getTrendsSI(startDate, endDate) {
      const _startDate = moment(startDate);
      const _endDate = moment(endDate);

      homeStockInTrend({
        start_date: startDate,
        end_date: endDate,
        warehouse: this.curWarehouse,
      }).then((data) => {
        const stockInTrendData = [];
        const diffDays = _endDate.diff(_startDate, "days");

        for (let index = 0; index < diffDays; index++) {
          const date = _startDate.clone().add(index, "days").format("YYYY-MM-DD");
          const dataItem = { date, quantity: 0 };
          stockInTrendData.push(dataItem);

          for (let item of data.results) {
            if (moment(item.date).format("YYYY-MM-DD") == date) {
              dataItem.quantity = item.quantity;
              break;
            }
          }
        }

        this.StockInTrendData = stockInTrendData;
        this.container4.changeData(this.StockInTrendData);
      });
    },
    getTrendsSO(startDate, endDate) {
      const _startDate = moment(startDate);
      const _endDate = moment(endDate);

      homeStockOutTrend({
        start_date: startDate,
        end_date: endDate,
        warehouse: this.curWarehouse,
      }).then((data) => {
        const StockOutTrendData = [];
        const diffDays = _endDate.diff(_startDate, "days");

        for (let index = 0; index < diffDays; index++) {
          const date = _startDate.clone().add(index, "days").format("YYYY-MM-DD");
          const dataItem = { date, quantity: 0 };
          StockOutTrendData.push(dataItem);

          for (let item of data.results) {
            if (moment(item.date).format("YYYY-MM-DD") == date) {
              dataItem.quantity = item.quantity;
              break;
            }
          }
        }

        this.StockOutTrendData = StockOutTrendData;
        this.container5.changeData(this.StockOutTrendData);
      });
    },
    getTrendsInventory(startDate, endDate) {
      const _startDate = moment(startDate);
      const _endDate = moment(endDate);

      homeStockTrend({
        start_date: startDate,
        end_date: endDate,
        warehouse: this.curWarehouse,
      }).then((data) => {
        const InventoryTrendData = [];
        const diffDays = _endDate.diff(_startDate, "days");

        for (let index = 0; index < diffDays; index++) {
          const date = _startDate.clone().add(index, "days").format("YYYY-MM-DD");
          const dataItem = { date, quantity: 0 };
          InventoryTrendData.push(dataItem);

          for (let item of data.results) {
            if (moment(item.date).format("YYYY-MM-DD") == date) {
              dataItem.quantity = item.quantity;
              break;
            }
          }
        }

        this.InventoryTrendData = InventoryTrendData;
        this.container2.changeData(this.InventoryTrendData);
      });
    },
    getStoreInfo(startDate, endDate) {
      const _startDate = moment(startDate);
      const _endDate = moment(endDate);

      storeInfoList({
        start_time: startDate,
        end_time: endDate,
        warehouse: this.curWarehouse,
      }).then((data) => {
        const StoreInfoData = [];
        const diffDays = _endDate.diff(_startDate, "hours");

        for (let index = 0; index < diffDays; index++) {
          const date = _startDate.clone().add(index, "hours").format("YYYY-MM-DD HH:mm");
          const stockInItem = { date, quantity: 0, type: "入库" };
          const stockOutItem = { date, quantity: 0, type: "出库" };
          StoreInfoData.push(stockInItem);
          StoreInfoData.push(stockOutItem);

          for (let item of data.results) {
            item.date = moment(item.date).format("YYYY-MM-DD HH:mm");
            if (item.date === date && item.type === "入库") {
              stockInItem.quantity = item.quantity;
            }

            if (item.date === date && item.type === "出库") {
              stockOutItem.quantity = item.quantity;
            }
          }
        }

        this.StoreInfoData = StoreInfoData;
        this.container1.changeData(this.StoreInfoData);
      });
    },
    changeDateRangeStockIn(dateRange) {
      this.dateRangeStockIn = dateRange;
      this.getTrendsSI(dateRange[0].format("YYYY-MM-DD"), dateRange[1].clone().add(1, "days").format("YYYY-MM-DD"));
    },
    changeDateRangeStockOut(dateRange) {
      this.dateRangeStockOut = dateRange;
      this.getTrendsSO(dateRange[0].format("YYYY-MM-DD"), dateRange[1].clone().add(1, "days").format("YYYY-MM-DD"));
    },
    changeDateRangeInventory(dateRange) {
      this.dateRangeInventory = dateRange;
      this.getTrendsInventory(dateRange[0].format("YYYY-MM-DD"), dateRange[1].clone().add(1, "days").format("YYYY-MM-DD"));
    },
    changeDateRangeStoreInfo(dateRange) {
      this.dateRangeStoreInfo = dateRange;
      this.getStoreInfo(
        dateRange[0].format("YYYY-MM-DD HH:mm:ss"),
        dateRange[1].clone().add(1, "days").format("YYYY-MM-DD HH:mm:ss")
      );
    },
  },
  mounted() {
    this.initData();
  },
};
</script>

<style lang="less" scoped>
.smallCard {
  width: 50%;
  text-align: center;
  box-shadow: 0 0 0 #888888;
}
.chartTitle {
  font-size: 14px;
  font-weight: bold;
}
.number {
  font-size: 24px;
  height: 50%;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
}
.intro {
  height: 50%;
  font-size: 14px;
  color: #666;
  display: flex;
  align-items: center;
  justify-content: center;
}
.percent {
  height: 45px;
  font-size: 20px;
  text-align: left;
  color: #1890ff;
  &::before {
    content: "占用率:";
    font-size: 12px;
  }
  &::after {
    content: "%";
    font-size: 12px;
  }
}
</style>
